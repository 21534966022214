var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("少年三国志2-抽奖")]),_c('el-card',{staticClass:"lottery-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"el-icon-present"}),_c('span',[_vm._v("抽奖说明")])]),_c('div',{staticClass:"lottery-content"},[_c('h4',[_vm._v("奖品列表")]),_c('ul',_vm._l((this.Getlist.list),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item))])}),0),_c('h4',[_vm._v("发奖方式")]),_c('p',[_vm._v("尊贵的玩家，抽中后请您第一时间截图抽中界面，然后联系我们的VIP客服进行领取")])])]),_c('el-form',{ref:"Raffleinfo",staticClass:"demo-ruleForm",attrs:{"model":_vm.Raffleinfo}},[_c('el-form-item',{attrs:{"label":"玩家昵称","prop":"name","rules":[
            { required: true, message: '昵称不能为空' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.Raffleinfo.name),callback:function ($$v) {_vm.$set(_vm.Raffleinfo, "name", _vm._n($$v))},expression:"Raffleinfo.name"}})],1),_c('el-form-item',{attrs:{"label":"玩家ID","prop":"ID","rules":[
            { required: true, message: 'ID不能为空' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.Raffleinfo.ID),callback:function ($$v) {_vm.$set(_vm.Raffleinfo, "ID", _vm._n($$v))},expression:"Raffleinfo.ID"}})],1),_c('el-form-item',{attrs:{"label":"玩家区服","prop":"district","rules":[
            { required: true, message: '区服不能为空' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.Raffleinfo.district),callback:function ($$v) {_vm.$set(_vm.Raffleinfo, "district", _vm._n($$v))},expression:"Raffleinfo.district"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('Raffleinfo')}}},[_vm._v("开始抽奖")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }